<template>
  <div class="wrap">
    <div class="top-banner">
      <div class="w-1200">
        <div class="title">数据服务平台</div>
        <div class="en">DU-DSP</div>
        <p>产品主要提供数据服务的管理功能，基于已有数据中台或数据发布服务；</p>
        <p>产品按小而美的理念去设计，摒弃百年不用的功能，让用户专注常用功能。</p>
      </div>
    </div>
    <div class="main-box w-1200">
      <div class="main-item">
        <div class="title">功能介绍</div>
        <div class="content">
          <div class="function-box">
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-1.png"/>
              <label>数据源管理</label>
              <p>新增数据库，作为数据服务的数据来源。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-2.png"/>
              <label>服务目录</label>
              <p>可查看管理数据服务，包含接口文档的查看、导出，服务编辑与删除等功能。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-3.png"/>
              <label>新增服务</label>
              <p>可通过托拉拽配置方式或者SQL代码方式新增服务，新增服务时可直接配置服务发布方，授权服务使用方等，一键发布服务，避免多步操作。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-4.png"/>
              <label>服务测试</label>
              <p>可通过输入服务参数的方式，快速测试服务。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-5.png"/>
              <label>内存加速</label>
              <p>针对性能要求较高的服务，可选择内存加速功能，实现毫秒级响应。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-6.png"/>
              <label>服务返回自定义</label>
              <p>可指定服务返回节点名称，服务返回节点类型，可选择对象或者数组，满足不同场景的使用需求。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-7.png"/>
              <label>服务记录</label>
              <p>提供服务记录查询，包括响应时长，服务状态，输入输出报文等信息。</p>
            </div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-8.png"/>
							<label>服务网关</label>
							<p>提供授权、认证、限流、路由等功能。</p>
						</div>
          </div>
        </div>
      </div>
      <div class="main-item">
        <div class="title">产品架构</div>
        <div class="content">
          <img src="~@/assets/images/dsp-architecture.png"/>
        </div>
      </div>
      <div class="main-item">
        <div class="title">核心优势</div>
        <div class="content">
          <div class="core-box">
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-1.png"/>
              <div class="text-box">
                <label>快速提供数据服务</label>
                <p>对接中台数据或已有系统数据，快速提供数据服务。</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-2.png"/>
              <div class="text-box">
                <label>实时/异步服务</label>
                <p>支持实时/异步服务，服务发布时可根据数据特性与服务的要求，选择实时或异步服务。</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-3.png"/>
              <div class="text-box">
                <label>统一服务管理</label>
                <p>将服务发布、服务授权，服务目录纳入统一管理，分析服务的使用情况，耗时情况，可针对服务本身做适当的优化。</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-4.png"/>
              <div class="text-box">
                <label>主流数据库支持</label>
                <p>支持基于主流数据库提供数据服务，如：mysql、Oracle等传统数据库，Hive、es、Hbase、Clickhouse等大数据库，Gbase、达梦等国产数据库</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="main-item">
        <div class="title">应用场景</div>
        <div class="content">
          <div class="scenario-box">
            <div class="scenario-item">
              <label>数据分析，面向企业精细化运营</label>
              <p>汇聚处理各类业务数据，基于中台强大的引擎能力完成复杂的分析与监控工作，灵活响应并满足新需求，实现对用户千人千面的精细化运营</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
            <div class="scenario-item">
              <label>数据治理，为企业数据保驾护航</label>
              <p>针对各种来源的数据，统一数据标准、统一数据资产，同时保障数据安全，从组织和个人角度，促进数据在组织内无障碍地进行交流</p>
              <img src="~@/assets/images/rdc/scenario-2.png"/>
            </div>
            <div class="scenario-item">
              <label>数据应用，助力企业数字化转型</label>
              <p>分析、管理数据，基于硬件和业务数据，完成企业的事前风险预测、事中监控调度和事后问题排查工作，保障生产和供应的可靠性</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #F7F8FA;
}
.top-banner {
  height: 320px;
  background-image: url('~@/assets/images/product/banner.png');
  background-repeat: no-repeat;
  background-position: center;
  .title {
    font-size: 40px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding-top: 82px;
  }
  .en {
    font-size: 40px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 100;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 14px;
  }
}
.main-box {
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #4F5969;
    padding: 40px 0;
  }
  .content {
    img {
      width: 1199px;
    }
  }
  .function-box {
    overflow: hidden;
    .function-item {
      box-sizing: border-box;
      float: left;
      width: 285px;
      height: 244px;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 30px;
      margin-right: 19px;
      >img {
        width: 26px;
      }
      &:nth-child(-n+4) {
        margin-bottom: 19px;
      }
      &:nth-child(4n){
        margin-right: 0;
      }
      label {
        display: block;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        color: #1D2129;
      }
      p {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        line-height: 1.2;
      }
    }
  }
  .core-box {
    overflow: hidden;
    .core-item {
      padding: 40px 28px 40px 30px;
      float: left;
      box-sizing: border-box;
      width: 590px;
      height: 159px;
      background: #FFFFFF;
      border-radius: 5px;
			margin-bottom: 20px;
      >img {
        width: 72px;
      }
      &:nth-child(2n-1) {
        margin-right: 20px;
      }
      img {
        float: left;
      }
      .text-box {
        margin-left: 102px;
        label {
          font-size: 24px;
          font-weight: 400;
          color: #1D2129;
        }
        p {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #87909C;
          line-height: 1.2;
        }
      }
    }
  }
  .scenario-box {
    overflow: hidden;
    margin-bottom: 60px;
    .scenario-item {
      padding-top: 30px;
      float: left;
      width: 386px;
      background: #FFFFFF;
      border-radius: 5px;
      >img {
        width: 386px;
      }
      &:not(:last-child){
        margin-right: 19px;
      }
      label {
        font-size: 20px;
        font-weight: 400;
        color: #1D2129;
        padding: 30px 30px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        margin-top: 22px;
        margin-bottom: 30px;
        padding: 0 30px;
        height: 77px;
        line-height: 1.2;
      }
    }
  }
}
</style>
